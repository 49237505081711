import React from 'react'
import styled from 'styled-components'
import * as colors from 'config/theme/colors'
import media from 'config/theme/media'

const AlertContainer = styled.div`
  background-color: ${({ theme }) => (theme.error ? theme.error : colors.alert())};
  padding: 20px;
  color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: row; 
  align-items: flex-start; 
 
  ${media.lessThan('md')` 
    flex-direction: column;
    align-items: center; 
    `}
`

const AlertIcon = styled.div`
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: ${({ theme }) => (theme.error ? theme.error : colors.alert())};
  border-radius: 50%;
  margin-right: 10px; 
  border: 2px solid white;
  flex-shrink: 0;
  
  ${media.lessThan('md')`
    margin-right: 0;  
    margin-bottom: 10px;  
  `}
`

export const AlertMessage = (props) => {
  const { children } = props;

  return (
    <AlertContainer {...props}>
      <AlertIcon>!</AlertIcon>
      {children}
    </AlertContainer>
  )
}

