import React, { useEffect, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import useTranslate from '../hooks/useTranslate'
import { useDispatch, useSelector } from 'react-redux'
import { actions as organisationDetailsActions } from 'store/modules/organisationDetails'
import { actions as authActions, selectors as authSelectors } from 'store/modules/auth'
import { useHCPProfessions, usePublicProfessions } from '../hooks/organisationDetailsHooks'
import UserRegisterForm from './Forms/UserRegisterForm'
import H2 from './Typography/H2'
import { AlertMessage } from './AlertMessage'
import Button from './Button'
import Link from './Link'
import media from '../config/theme/media'
import useUserInitialValues from '../hooks/useUserInitialValues'

const ModalWrapper = styled.div`
  width: 70%;
  max-height: 80%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  z-index: 1000;  
  border-radius: 12px;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 40px;
  pointer-events: auto; 
  ${media.lessThan('sm')` 
  width: 80%;
  `}
`

const ModalBg = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999; 
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto; 
`

const CenteredH2 = styled(H2)`
  text-align: center; 
  width: 100%; 
`

const AlertMessageContent = styled.div`
  display: flex;
  flex-direction: row; 
    ${media.lessThan('md')` 
  flex-direction: column; 
  `}
  align-items: center; 
  justify-content: space-between; 
  gap: 10px;
  width: 100%;
`

const LogoutButton = styled(Button)`
  background-color: transparent;
  color: white;
  box-shadow: none;
  padding: 8px 16px;
  border: 2px solid white; 
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`
const Alert = styled(AlertMessage)`
  margin-bottom: 20px;
  width: 60%;

${media.lessThan('md')` 
  width: 80%;
`}
`
const SuccessText = styled.h2`
  font-weight:bold;
`

const ThankYouText = styled.p`
  font-size: 16px;
`

const GatekeepModal = (missingField) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isUpdated, setIsUpdated] = useState(false)
  const dispatch = useDispatch()
  const translate = useTranslate()
  const user = useSelector(authSelectors.getCurrentUser)
  const publicProfessions = usePublicProfessions()
  const hcpProfessions = useHCPProfessions()

  const userInitialValues = useUserInitialValues({user, publicProfessions, hcpProfessions})

  useEffect(() => {
    dispatch(organisationDetailsActions.fetchOrganisationDetails())
  }, [])

  const onUpdateUserDetails = useCallback((userFields) => {
    dispatch(authActions.updateUser(userFields))
    setIsUpdated(true)  
  })

  const handleContinue = () => {
    setIsOpen(false)  
  }

  if (!isOpen) return null

  return (
    <ModalBg>
      <ModalWrapper>
        {!isUpdated ?(
          <>
           <CenteredH2>{translate("COMPLETE_PROFILE")}</CenteredH2>
            <Alert>
              <AlertMessageContent>
                <span>{translate('MISSING_PROFILE_FIELD', missingField)}</span>
                <Link href="/logout" noDecoration>
                  <LogoutButton onClick={() => setIsOpen(false)} small>{translate("LOGOUT")}</LogoutButton>
                </Link>
              </AlertMessageContent>
            </Alert>
  
            <UserRegisterForm
              variant={'account'}
              fieldOptions={{
                publicprofession: publicProfessions,
                profession: hcpProfessions
              }}
              parseError={authActions.parseError}
              onSubmit={onUpdateUserDetails}
              initialValues={userInitialValues}
              successMessage={translate('CHANGE_MY_DETAILS_SUCCESS')}
              buttonLabel={translate('BUTTON_LABEL_UPDATE')}
              enableReinitialize
            />
          </>
        ) : (
          <>
          <SuccessText>{translate('CHANGE_MY_DETAILS_SUCCESS')}</SuccessText>
          <ThankYouText>{translate('UPDATE_REQUIRED_PROFILE_FIELD_SUCCESS')}</ThankYouText>
          <Button onClick={handleContinue}>
            {translate('CONTINUE_TO_WEBSITE')}
          </Button>
          </>
        )}
      </ModalWrapper>
    </ModalBg>
  ) 
}

export default GatekeepModal
