import 'isomorphic-unfetch'
import '@reach/tabs/styles.css'
import '@reach/skip-nav/styles.css'
import '@reach/listbox/styles.css'

import { Provider } from 'react-redux'
import App from 'next/app'
import Router from 'next/router'
import withRedux from 'next-redux-wrapper'
import { PersistGate } from 'redux-persist/integration/react'
import { createGlobalStyle } from 'styled-components'
import _ from 'lodash'

import makeStore from '../store/makeStore'
import * as colors from '../config/theme/colors'
import { bodyFont } from '../config/theme/fonts'
import media from 'config/theme/media'
import { actions as resourcesActions, selectors as resourcesSelectors } from '../store/modules/resources'
import { selectors as authSelectors } from '../store/modules/auth'
import { actions as configActions } from '../store/modules/config'
import formViewActions from '../store/modules/formViews/actions'
import { actions as orgDetailsActions } from '../store/modules/organisationDetails'

import * as analyticsService from '../services/analyticsService'

import { FullPageLoader } from '../components/Loader'
import CookiePolicy from '../components/CookiePolicy'
import websiteKeys from '../websiteConfig/websiteKeys.json'
import websiteConfig from '../websiteConfig'
import { WebsiteContext } from '../services/websiteContext'
import { contentfulClient, contentfulPreviewClient } from '../services/contentfulService'
import { GTMNoScript } from '../components/GTM'
import vars from 'config/theme/vars'
import * as Sentry from '@sentry/nextjs'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GatekeepModal from '../components/GatekeepModal'

const GlobalStyles = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: ${colors.white()};
    font-family: ${bodyFont};
    color: ${colors.black()};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    scroll-behavior: smooth;
  }


  @media print {
    @page {
      size: auto;
      size: A4;
    }
  }

  body, #__next {
    margin: 0px;
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }

  .cookie-save-button {
    margin: 20px 0 40px 0;
  }

  .cookie-link {
    color: ${colors.hyperlink()} !important;
  }

  .cookie-link svg {
    height: 16px;
    width: 16px;
  }

  a {
    color: ${colors.hyperlink()};
  }

  /**
   * Sisense.JS injects dependencies and one is called 'jQuery-ui-Slider-Pips - v1.11.4'
   * This library removes the 'outline' from everything. Re-adding browsers default styling. 
  */
  body *:focus {
    outline: 5px auto #999;
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  [data-reach-tab-list] {
    background: transparent;
  }

  [data-reach-dialog-overlay] {
    background: hsla(0, 0%, 0%, 0.33);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: ${vars.zIndex.modalOverlay};
    align-items: center;
    display: flex;
  }

  [data-reach-dialog-content] {
    width: min-content;
    max-width: 600px;
    margin: 0 auto;
    background: white;
    padding: 1.5rem;
    outline: none;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  [data-reach-listbox-button] {
    border: 1px solid ${colors.inputBorderColor};
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    ${media.greaterThan('md')`
      font-size: 16px;
    `}
  }

  [data-reach-listbox-list] {
    max-height: 300px;
    overflow: auto;
  }

  [data-reach-listbox-popover] {
    z-index: 1;
    width: 100%;
  }

  [data-reach-listbox-option] {
    padding: 8px 10px;
    white-space: normal;
    font-size: 12px;
    ${media.greaterThan('md')`
      font-size: 14px;
    `}
  }

  [data-reach-listbox-option][aria-selected] {
    background-color: ${colors.primaryOffset};
  }
`
// merge local env variables
const getLocalConfig = ({ website, environment }) => {
  const envFolder = websiteConfig[website]
  const mergeVariables = _.merge(
    envFolder['config'],
    envFolder[environment]
  )
  return mergeVariables
}

// get local websiteConfig based on hostname
const determineWebsiteConfig = ({ host }) => {
  const development = _.includes(host, 'localhost')
  if (development) {
    return getLocalConfig({
      website: process.env.website,
      environment: 'development'
    })
  } else {
    const matchedHost = _.pickBy(websiteKeys, (value, key) => key === host)
    return getLocalConfig({
      website: _.values(matchedHost)[0],
      environment: process.env.REACT_APP_NODE_ENV
    })
  }
}

// Google analytics page view
// https://github.com/zeit/next.js/blob/canary/examples/with-google-analytics
Router.events.on('routeChangeComplete', (url) => {
  analyticsService.pageview(url)
})

/**
 * @description required to use redux (withRedux)
 * https://github.com/kirill-konshin/next-redux-wrapper
 * @class MHRAPandemicApp
 * @extends {App}
 */
class MHRAPandemicApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}
    let env
    const dispatch = ctx.store.dispatch
    const getState = ctx.store.getState
    const host = _.get(ctx, 'req.headers.host')
    if (ctx.isServer) {
      env = determineWebsiteConfig({ host })
    }
    if (env) {
      // set contentful credentials
      contentfulClient.setCredentials(env)
      contentfulPreviewClient.setCredentials(env)

      // set website config to process.env and redux store
      _.mapKeys(env, (value, key) => {
        process.env[key] = value
      })
      await dispatch(configActions.setWebsiteConfig({ websiteConfig: env }))
    }

    if (Component.getInitialProps) {
      pageProps = {
        ...await Component.getInitialProps(ctx)
      }
    }

    /**
       * @description fetch required resources for every page if they don't already exist
       */
    const requiredResources = [
      'layout',
      'announcement',
      'dashboard',
      'watchlistProduct',
      'reportMainPage'
    ]
    for (const resource of requiredResources) {
      const existingResources = resourcesSelectors.resourceTypeSelector(getState())(resource)
      if (_.isEmpty(existingResources)) {
        await dispatch(resourcesActions.fetchResources({
          resourceType: resource,
          include: 3
        }))
      }
    }

    /**
       * @description used for dynamic sitemap.xml generation
       * MAH-2152 - and for robots.txt
       */
    const staticPages = [
      '/sitemap.xml',
      '/sitemap.json',
      '/robots.txt'
    ]
    if (ctx.isServer && _.includes(staticPages, ctx.req.originalUrl)) {
      pageProps = {
        ...pageProps,
        baseUrl: 'https://' + ctx.req.get('host'),
        res: ctx.res
      }
    }
    return { pageProps }
  }

  componentDidMount() {
    const storeState = this.props.store.getState()
    const config = _.get(storeState, 'config.websiteConfig')

    if (config) {
      if (!contentfulClient.getClient()) {
        contentfulClient.setCredentials(config)
  
      }
      if(!contentfulPreviewClient.getClient()) {
        contentfulPreviewClient.setCredentials(config)
      }
      if (!config.useProductAPI) {
        this.props.store.dispatch(formViewActions.fetchAllFormViews())
      }
      this.props.store.dispatch(orgDetailsActions.fetchOrganisationDetails())
      _.mapKeys(config, (value, key) => {
        process.env[key] = value
      })
      Sentry.setTag("websiteKey", _.get(config, 'websiteKey'))
      Sentry.setTag("environment", _.get(config, 'nodeEnv'))
    }
  }

  render() {
    const { Component, pageProps, store, isServer, router } = this.props
    const hideCookieOnPathname = [
      '/external/',
      '/idaps/[drugName]/print'
    ]
    const shouldHideCookiePolicy = _.includes(hideCookieOnPathname, _.get(router, 'pathname'))
    const state = store.getState()
    const storeWebsiteConfig = _.get(state, 'config.websiteConfig')
    const theme = {
      primaryColor: _.get(storeWebsiteConfig, 'primaryColor'),
      primaryContrastColor: _.get(storeWebsiteConfig, 'primaryContrastColor')
    }
    const gtmId = _.get(storeWebsiteConfig, 'gtmId', undefined);
    const userProfileIncomplete = !authSelectors.getUserOccupation(state)
    const userIsLoggedIn = authSelectors.getIsLoggedIn(state)
    const userIsAdmin = authSelectors.getIsUserAdmin(state)
    if (isServer) {
      return (
        <WebsiteContext.Provider value={storeWebsiteConfig}>
          <Provider
            store={store}
          >
            {gtmId && <GTMNoScript gtmId={gtmId} />}
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
             {userProfileIncomplete &&  userIsLoggedIn && !userIsAdmin && <GatekeepModal missingField={'Profession'}/>}
            <Component
              {...pageProps}
              websiteConfig={storeWebsiteConfig}
            />
            {!shouldHideCookiePolicy && <CookiePolicy />}
            <GlobalStyles theme={theme} />
          </Provider>
        </WebsiteContext.Provider>
      )
    }

    return (
      <WebsiteContext.Provider value={storeWebsiteConfig}>
        <Provider
          store={store}
        >
          {gtmId && <GTMNoScript gtmId={gtmId} />}
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
            <PersistGate persistor={store.__persistor} loading={<FullPageLoader />}>
            {userProfileIncomplete &&  userIsLoggedIn && !userIsAdmin && <GatekeepModal missingField={'Profession'}/>}
              <Component
                {...pageProps}
                websiteConfig={storeWebsiteConfig}
              />
              {!shouldHideCookiePolicy && <CookiePolicy />}
            </PersistGate>
          <GlobalStyles theme={theme} />
        </Provider>
      </WebsiteContext.Provider>
    )
  }
}
export default withRedux(makeStore, { debug: false })(MHRAPandemicApp)
